import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import { StaticImage } from 'gatsby-plugin-image';
import ButtonContact from '../components/ButtonContact';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Clients from '../components/clients/Clients';
import StatsBox from '../components/StatsBox';

const Index = () => (
  <Layout>
    <section id="hero" className="min-h-screen pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-3xl text-white lg:text-3xl xl:text-4xl font-bold leading-snug lg:leading-snug xl:leading-snug">
            Acelere suas vendas enviando seus pedidos em menos de 1 hora
          </h1>
          <p className="text-xl text-white lg:text-2xl mt-6 font-light">
            Nossos centros logísticos urbanos inteligentes{' '}
            <strong>armazenam, empacotam e enviam</strong> os pedidos do seu ecommerce em poucos
            minutos.
          </p>
          <p className="mt-8 md:mt-12">
            <AnchorLink
              className="text-white font-bold"
              title="Nossa solução"
              to="/#servicos"
              stripHash
            >
              <Button>Conheça nossa solução</Button>
            </AnchorLink>
          </p>
        </div>
        <div className="lg:w-1/2 text-center">
          <StaticImage
            src="../assets/images/dedalog-hero.png"
            placeholder="blurred"
            layout="constrained"
            alt="Dedalog Hero Image"
          />
        </div>
      </div>
    </section>
    <section id="clientes" className="py-5 px-10 mx-auto text-center">
      <div className="flex flex-col md:flex-row sm:-mx-3 mt-12">
        <Clients />
      </div>
    </section>
    <section id="servicos" className="py-14 lg:pb-30">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl lg:text-4xl font-semibold">
          Do carrinho de compras à casa do cliente
        </h2>
        <p className="py-5 text-lg px-10 sm:px-20 lg:px-60">
          A Dedalog é uma rede de centros de micro-fulfillment que funcionam com a agilidade que
          seus clientes exigem: quando um pedido é aprovado em sua loja, em poucos minutos ele é
          faturado, separado e enviado através da nossa rede de transportadoras parceiras. Fidelize
          seus clientes com entregas no mesmo dia ou em até 48h para as principais capitais do
          Brasil.
        </p>
        <div className="flex flex-col md:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <StaticImage
                src="../assets/images/Rapidez.svg"
                placeholder="blurred"
                layout="constrained"
                alt="Dedalog Rapidez"
                height={100}
              />
              <p className="pt-4 font-semibold text-xl">Rapidez</p>
              <p className="mt-4">
                Integramos em tempo real seus canais de venda com a plataforma logística da Dedalog
                para prepararmos seus envios em poucos minutos.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <StaticImage
                src="../assets/images/Tecnlogia.svg"
                placeholder="blurred"
                layout="constrained"
                alt="Dedalog Tecnlogia"
                height={100}
              />
              <p className="pt-4 font-semibold text-xl border-t border-gray-400 mx-14">
                Tecnologia
              </p>
              <p className="mt-4">
                Da gestão do seu estoque ao monitoramento inteligente dos fretes, a plataforma
                logística da Dedalog antecipa qualquer problema relacionado à operação logística do
                seu e-commerce.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <StaticImage
                src="../assets/images/Economia.svg"
                placeholder="blurred"
                layout="constrained"
                alt="Dedalog Economia"
                height={100}
              />
              <p className="pt-4 font-semibold text-xl">Economia</p>
              <p className="mt-4">
                Redução de até 50% nos custos de frete através do algoritmo de otimização de fretes.
                Frete mais barato e rápido resulta em aumento nas suas conversões e na retenção de
                clientes satisfeitos.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="tecnologia"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Conectados</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Nós conectamos sua loja ou marketplace em nosso sistema de gerenciamento de pedidos. O
            cliente faz a compra em sua loja e nós recebemos a solicitação de envio em tempo real.
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../assets/images/RealTimeSync.svg"
          placeholder="blurred"
          layout="constrained"
          alt="Dedalog Conectados"
        />
      }
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Produtos na estante</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Nós coletamos e armazenamos seu estoque em um de nossos Centros de Fulfillment.
            Conferimos, estocamos e monitoramos seus SKUs constantemente.
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../assets/images/BuildingBlocks.svg"
          placeholder="blurred"
          layout="constrained"
          alt="Dedalog Estante"
        />
      }
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Expresso ou econômico</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Economize em fretes diversificando sua rede de entregas com diversas transportadoras e
            surpreenda seu cliente entregando seus produtos em poucas horas ou em até 48h.
          </p>
        </div>
      }
      secondarySlot={
        <StaticImage
          src="../assets/images/Delivery.svg"
          placeholder="blurred"
          layout="constrained"
          alt="Dedalog Delivery"
        />
      }
    />

    <section id="stats" className="py-10 lg:pt-16">
      <div className="container mx-auto text-center">
        <h3 className="text-xl lg:text-2xl text-gray-600">Uma logística que funciona</h3>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full px-12 sm:w-1/3">
            <StatsBox primaryText="+ 99,5%" secondaryText="Precisão no picking/packing" />
          </div>
          <div className="w-full px-12 sm:w-1/3">
            <StatsBox
              primaryText="< 48h"
              secondaryText="Prazo médio de entrega expressa nas capitais"
            />
          </div>
          <div className="w-full px-12 sm:w-1/3">
            <StatsBox
              primaryText="1:1"
              secondaryText="Um gerente de contas sempre à sua disposição"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="precos" className="bg-blue-200 py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <h3 className="text-2xl lg:text-4xl font-semibold">Taxas 100% transparentes</h3>
        <p className="pt-2">
          O fulfillment do seu ecommerce no piloto automático: rápido, escalável e acessível
        </p>
      </div>
      <div className="container mx-auto">
        <div className="text-center mt-8 px-2 lg:px-56">
          <AnchorLink
            className="text-white font-bold"
            title="Simular preços"
            to="/precos"
            stripHash
          >
            <Button>Simular Preços</Button>
          </AnchorLink>
        </div>
      </div>
    </section>

    <section className="container px-6 sm:mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">
        Pronto para colocar sua logística
        <br />
        no piloto automático?
      </h3>
      <p className="mt-8 px-8 text-xl font-light">
        Clique no botão abaixo para preencher seus dados que entraremos em contato o mais breve
        possível. Ou então clique no botão de chat para falar com um especialista imediatamente.
      </p>
      <div className="mt-8">
        <ButtonContact />
      </div>
    </section>
  </Layout>
);

export default Index;
