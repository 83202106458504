import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Clients = () => {
  const client_logo_height = 40;
  return (
    <>
      <div className="flex-1 px-3 py-4 lg:py-0">
        <a href="https://todavialivros.com.br/" targe="_blank">
          <StaticImage
            src="../../assets/images/todavia.png"
            placeholder="blurred"
            layout="constrained"
            alt="Todavia Livros"
            height={client_logo_height}
          />
        </a>
      </div>
      <div className="flex-1 px-3 py-4 lg:py-0">
        <a href="https://pegeloug.com.br/" targe="_blank">
          <StaticImage
            src="../../assets/images/pegeloug.png"
            placeholder="blurred"
            layout="constrained"
            alt="PegeLoug"
            height={client_logo_height}
          />
        </a>
      </div>
      <div className="flex-1 px-3 py-4 lg:py-0">
        <a href="https://boitempoeditorial.com.br/" targe="_blank">
          <StaticImage
            src="../../assets/images/boitempo.png"
            placeholder="blurred"
            layout="constrained"
            alt="Boitempo"
            height={client_logo_height}
          />
        </a>
      </div>
      <div className="flex-1 px-3 py-4 lg:py-0">
        <a href="https://tramabox.com.br/" targe="_blank">
          <StaticImage
            src="../../assets/images/tramabox.png"
            placeholder="blurred"
            layout="constrained"
            alt="Tramabox"
            height={client_logo_height}
          />
        </a>
      </div>
      <div className="flex-1 px-3 py-4 lg:py-0">
        <a href="https://www.doispontos.com.br/" targe="_blank">
          <StaticImage
            src="../../assets/images/doispontos.png"
            placeholder="blurred"
            layout="constrained"
            alt="Livraria DoisPontos"
            height={client_logo_height}
          />
        </a>
      </div>
    </>
  );
};

export default Clients;
